<template>
	<div id="wxappEdit" class="el-content">
		<a-form :label-col="{span:2}" :wrapper-col="{span:8}">
			<a-form-item label="应用版本" v-if="versionList && versionList.length">
				<a-radio-group v-model:value="form.server_id">
					<a-radio v-for="(item, index) in versionList" :value="item.server_id" :key="index" :disabled="item.limit == 0 && item.is_try == 0">
						{{ item.server_name }}
						<span v-if="item.limit == 0 && item.is_try == 1" style="color: red;">({{ item.try_day }}天试用)</span>
						<span v-else>（剩余:{{ item.limit }}个）</span>
					</a-radio>
				</a-radio-group>
			</a-form-item>

			<a-form-item label="创建方式" v-if="!program_id && wx_open_switch == '1'">
				<a-radio-group v-model:value="create_type" @change="changeCreateMethod">
					<a-radio :value="1">手动创建</a-radio>
					<a-radio :value="2">微信小程序授权</a-radio>
				</a-radio-group>
			</a-form-item>

			<div v-if="create_type == 1">
				<a-form-item label="应用名称"><a-input v-model:value="form.program_name"></a-input></a-form-item>
				<a-form-item label="应用LOGO">
					<kd-img-select @change="e => {form.logo = e;}" :src="form.logo"></kd-img-select>
				</a-form-item>
			</div>
			<div v-if="wx_open_switch == '1' && program_id">
				<a-form-item label="使用微信公众号授权">
					<a-radio-group v-model:value="form.config.wx_use_open">
						<a-radio value="1">第三方</a-radio>
						<a-radio value="0">非第三方</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="使用微信小程序授权">
					<a-radio-group v-model:value="form.config.wx_mini_use_open">
						<a-radio value="1">第三方</a-radio>
						<a-radio value="0">非第三方</a-radio>
					</a-radio-group>
				</a-form-item>
			</div>

			<a-form-item :wrapper-col="{offset:2}">
				<a-space>
					<a-button type="primary" @click="submitSave">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import setModel from '@/api/set';
import { useRoute } from 'vue-router';
import programModel from '@/api/saas/app';
import tool from '@/util/tool';
import router from '@/router'
import store from '@/store'
export default {
	setup(){
		const state = reactive({
			program_id: 0,
			form: {
				server_id: '',
				program_name: '',
				logo: '',
				is_trail: 0,
				config: {
					wx_use_open: '0',
					wx_mini_use_open: '0'
				}
			},
			versionList: [],
			tryData: [], //可试用信息
			is_try: false,
			wx_open_switch: '0', //是否开启第三方授权
			create_type: 1
		})
		const opt = useRoute().query
		
		setModel.getFarmSet(['wx_open_switch']).then(res=>state.wx_open_switch = res.wx_open_switch || '0')
		if( opt.auth_code ) addProgramAuth(opt.auth_code)
		if( !opt.program_id ) {
			checkCanCreate()
		}else{
			programModel.getProgramDetail(opt.program_id,res=>{
				state.program_id = opt.program_id
				console.log('res',res);
				
				state.form = tool.filterForm(Object.keys(state.form),res) || state.form
				if (!state.form.config) {
					state.form.config = {
						wx_use_open: '0',
						wx_mini_use_open: '0'
					}
				}
			})
		}
		

		function checkCanCreate(){
			programModel.checkCanCreateProgram(res=>{
				let title = res.message,is_try = false
				if( res.data ){
					let d = res.data
					state.versionList = d.list || []
					state.tryData = d.try || []

					let limit = 0 	//还可以购买个数
					state.versionList.forEach(item => {
						limit += parseInt(item.limit)
						if (item.is_try) {
							is_try = true;
						}
					})
					if (limit > 0 || ( store.state.userInfo && store.state.userInfo.rank == 1)) {
						return false
					} else {
						title = '您已经没有可创建的应用了哦，再去购买一个吧~';
					}
				}

				if (is_try) {
					tool.confirm(title,'立即购买','立即使用').then(()=>{
						// router.push({ path: '/admin/app/app_buy' })
					}).catch(()=>{})
				} else {
					tool.confirm(title,'立即购买','考虑一下').then(()=>{
						// router.push({ path: '/admin/app/app_buy' })
					}).catch(()=>{
						router.go(-1)
					})
				}
			})
		}

		function changeCreateMethod(e) {
			if (e == 2) getAuthUrl()
		}

		function submitSave(){
			let form = JSON.parse(JSON.stringify(state.form)), 
				{program_id,is_try,versionList } = state
			
			if( program_id ) form.program_id = program_id
			if( is_try ) form.is_trail = is_try	//使用版本
			
			// 判断当前创建版本是否为试用版
			versionList.forEach(item => {
				if (item.server_id == form.server_id) {
					if (item.limit == 0 && state.tryData.app_is_open_try == 1) {
						form.is_trail = 1 
					}
				}
			})
			programModel.addOrEditProgram(program_id ? 'edit':'add',form,()=>router.go(-1))
		}

		function getAuthUrl(){
			if( !tool.returnMessage(state.form.server_id,'请选择版本')) return
			versionList.forEach(item => {
				if (item.server_id == t.form.server_id) {
					if (item.limit == 0 && state.tryData.app_is_open_try == 1) {
						state.form.is_trail = 1;
					}
				}
			})
			sessionStorage.setItem('__AUTH_APP_FORM__', JSON.stringify(state.form))
			setModel.getWxOpenAuthUrl(res=>location.href = res)
		}

		function addProgramAuth(auth_code){
			let form = JSON.parse(sessionStorage.getItem('__AUTH_APP_FORM__'))
			if (form) {
				let data = {
					auth_code,
					auth_type: 'min_program',
					server_id: form.server_id,
					is_trail: form.is_trail
				}

				programModel.addOrEditProgramByAuth(data)
			}
		}

		return{
			...toRefs(state),
			submitSave,
			changeCreateMethod,
			getAuthUrl,
			addProgramAuth
		}
	}
}
</script>

<style></style>
